.z-index-1 {
  position: relative;
  z-index: 1;
}

.rs-base {
  z-index: 1000000;
  width: 420px;
  height: 500px;
}

.rs-counter-id {
  width: 150px;
}
